import React from "react"

import { Hero } from "../../components/blog/hero"
import { PostSidebar } from "../../components/blog/post-sidebar"
import { Breadcrumbs, BreadcrumbsItem } from "../../components/breadcrumbs"
import { Container } from "../../components/container"
import SEO from "../../components/seo"
import img1 from "./images/6-min.jpg"
import styles from "./post-grid.module.css"

const ZdraveStravovani = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={"Zdravé stravování - jak na něj? | NutritionPro"}
        description={
          "Většina z nás se snaží, aby naše životospráva byla co nejlepší. Snažíme se pravidelně cvičit, dělat věci, co nás baví a samozřejmě zdravě jíst. Abyste však věděli, že se stravujete správně, měli byste znát základy racionální výživy. Můžete si totiž myslet, že děláte všechno správně, ale výsledky, po kterých toužíte, se nedostavují. Pojďme si tedy shrnout úplné základy zdravého stravování."
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: "24px 0" }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Zdravé stravování - jak na něj?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Zdravé stravování - jak na něj?" date="08.02.2023" />
            <div>
              <p className={styles.postText}>
                Většina z nás se snaží, aby naše životospráva byla co nejlepší.
                Snažíme se pravidelně cvičit, dělat věci, co nás baví a
                samozřejmě zdravě jíst. Abyste však věděli, že se stravujete
                správně, měli byste znát základy racionální výživy. Můžete si
                totiž myslet, že děláte všechno správně, ale výsledky, po
                kterých toužíte, se nedostavují. Pojďme si tedy shrnout úplné
                základy zdravého stravování.
              </p>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: "40px" }}
                alt="img1"
              />

              <h5 className={styles.postTitle}>Základní živiny</h5>
              <p className={styles.postText}>
                Nejprve si představíme základní živiny. Jsou jimi{" "}
                <b>sacharidy, bílkoviny a tuky.</b> Základní trojpoměr těchto
                živin je zhruba 55 % sacharidů, 15 % bílkovin a 30 % tuků. To se
                však může individuálně lišit, především poměr bílkovin a tuků.
                Energetická hodnota sacharidů a bílkovin je 4 kcal (kilokalorie)
                na jeden gram. Tuky v jednom gramu obsahují 9 kcal.
              </p>

              <p className={styles.postText}>
                Sacharidy jsou <b>nejrychlejším zdrojem energie,</b> v některých
                případech výhradním zdrojem (mozek, červené krvinky). V těle se
                ukládají ve formě glykogenu a tvoří tak{" "}
                <b>zásobu energie pro naše tělo.</b> Také se, v podobě{" "}
                <b>vlákniny,</b> starají o zdraví našich střev. Rozlišujeme
                jednoduché a komplexní cukry. <b>Komplexní cukry</b> by měly
                tvořit minimálně 90 % z příjmu sacharidů. Jsou to například
                těstoviny, rýže, brambory a obiloviny. Příjem{" "}
                <b>jednoduchých cukrů</b> by měl být maximálně 10 % z celkového
                příjmu sacharidů. Jsou tělem rychle metabolizovány a rychle tak
                zvýší hladinu krevního cukru, což je pro nás nežádoucí.
                Jednoduché cukry se vyskytují například ve slazených nápojích,
                sušenkách a medu.
              </p>

              <p className={styles.postText}>
                Bílkoviny jsou <b>základními stavebními složkami</b> všech buněk
                v našem těle. Jsou nezbytné k výstavbě a obnově tkání. Z
                bílkovin, co přijmeme v potravě, se stávají{" "}
                <b>bílkoviny tělu vlastní.</b> Narozdíl od sacharidů a tuků,{" "}
                <b>bílkoviny v našem těle netvoří zásobu,</b> musíme je proto
                doplňovat neustále. Příjem bílkovin by měl být{" "}
                <b>alespoň 0,8 - 1 g/kg</b> hmotnosti člověka za den. Bílkoviny
                jsou <b>nejvíce sytící</b> složkou potravy. Rozlišujeme
                rostlinné a živočišné zdroje bílkovin. <b>Rostlinné</b> jsou
                například luštěniny, tofu nebo tempeh. <b>Živočišné</b>{" "}
                (plnohodnotné) jsou maso, vejce a mléčné výrobky.
              </p>

              <p className={styles.postText}>
                Tuky jsou <b>energeticky nejbohatší</b> složkou potravy. V těle
                tvoří zásobárnu energie, jsou nezbytné pro tvorbu hormonů a
                vstřebávání vitaminů rozpustných v tucích. Tuky můžeme
                rozdělovat podle více kritérií, pro nás je však nejdůležitější
                rozdělení podle toho, jaké <b>mastné kyseliny</b> tuk tvoří.{" "}
                <b>Nasycené mastné kyseliny</b> by měly tvořit maximálně 10 % z
                celkového energetického příjmu. U obyvatel ČR bývá toto číslo i
                několikanásobně vyšší, což může způsobit například
                kardiovaskulární onemocnění.
              </p>

              <p className={styles.postText}>
                Mezi jejich zdroje patří máslo, sádlo, kokosový a palmový olej.{" "}
                <b>Nenasycené mastné kyseliny</b> by měly tvořit zbývajících 20
                %. Mezi ty patří i proslulé <b>omega 3 a omega 6</b> mastné
                kyseliny, které jsou pro nás esenciální (naše tělo si je neumí
                vytvořit, ale potřebuje je, musíme je tedy přijímat potravou).{" "}
                <b>Zdrojem omega 3</b> mastných kyselin jsou například{" "}
                <b>tučné ryby,</b>{" "}
                <a href="https://nutritionpro.cz/blog/post-1/" target="_blank">
                  chia semínka
                </a>{" "}
                nebo řepkový olej. U populace ČR jich bývá často nedostatek,
                proto se běžně doporučuje jejich suplementace. Pozor na{" "}
                <b>trans-nenasycené</b> mastné kyseliny, které se vyskytují ve
                ztužených tucích. Měly by tvořit maximálně 1 % z celkového
                denního příjmu. Nesmíme opomenout ani nechvalně známý{" "}
                <b>cholesterol.</b> Pravda je taková, že{" "}
                <b>cholesterol potřebujeme.</b> Pro náš organismus je nezbytný a
                pokud jej nebudeme přijímat v potravě, naše tělo zvýší jeho
                syntézu (ano, vyrábíme si ho sami). Dávejte však pozor na jeho
                množství (cca 300 mg/den) a také přepalování, například při
                smažení.
              </p>

              <p className={styles.postText}>
                Dalšími důležitými složkami našeho jídelníčku jsou
                mikronutrienty, tedy <b>minerální látky a vitaminy</b> a
                samozřejmě také{" "}
                <a href="https://nutritionpro.cz/blog/post-3/">voda</a>.
              </p>

              <h5 className={styles.postTitle}>
                Co by nemělo chybět ve vašem jídelníčku
              </h5>
              <p className={styles.postText}>
                Váš jídelníček by měl obsahovat především{" "}
                <b>obiloviny a výrobky z nich,</b> tedy pečivo, rýže, těstoviny
                a další. Nejvhodnější variantou jsou <b>celozrnné a žitné</b>{" "}
                výrobky, jelikož obsahují více vlákniny a mají vyšší sytivost.
              </p>

              <p className={styles.postText}>
                Dále by se ve vašem jídelníčku měla objevovat{" "}
                <b>zelenina a ovoce,</b> a to až 5x denně. Příjem ovoce by se
                měl ideálně pohybovat okolo 200 g/den a zelenina okolo 400
                g/den. Ideální je střídat co nejvíce druhů pro pokrytí co
                nejširšího spektra vitaminů a minerálních látek. Fermentovaná
                zelenina obsahuje probiotické bakterie, které přispívají ke
                zdraví vaší mikrobioty a následně i imunitního systému. Nebojte
                se ani mraženého ovoce a zeleniny, uchovávají si všechny
                potřebné mikronutrienty.
              </p>

              <p className={styles.postText}>
                Nezapomínejte na <b>bílkoviny</b> ve formě masa, ryb, vajec,
                luštěnin, mléka a mléčných výrobků. Zařaďte především
                probiotické mléčné výrobky, jako je jogurt, kefír nebo
                acidofilní mléko. Podpoří správnou funkci trávicího traktu.
              </p>

              <p className={styles.postText}>
                Nezapomínejte ani na <b>tuky.</b> Oleje vždy vybírejte podle
                toho, do jaké kuchyně je chcete použít. Na smažení se hodí{" "}
                <b>rafinované oleje,</b> ideálně řepkový. Do studené kuchyně{" "}
                <b>panenské,</b> například olivový. Možností je ale spoustu.
                Snažte se do svého jídelníčku zařadit rostlinné zdroje tuku,
                jako jsou <b>rostlinné oleje, ořechy nebo avokádo.</b>
              </p>

              <p className={styles.postText}>
                Nezapomeňte si čas od času dát také něco,{" "}
                <b>na co máte zrovna chuť,</b> i když víte, že by se to na vašem
                stole nemělo vyskytovat příliš často. Všechno je o rovnováze.
                Dávejte sitaké pozor na <b>vhodnou tepelnou úpravu</b> (volte
                raději šetrnější verzi, jako je například horkovzdušná fritéza),{" "}
                <b>dosolování</b> jídla po uvaření a{" "}
                <b>dostatečný pitný režim</b> (který by měla tvořit především
                voda).
              </p>

              <h5 className={styles.postTitle}>Jak by měl vypadat váš talíř</h5>
              <p className={styles.postText}>
                Na závěr si řekneme, jak by měl zhruba vypadat{" "}
                <b>talíř s hotovým jídlem.</b> Talíř si pomyslně můžete rozdělit
                na <b>tři třetiny.</b> První třetina bude tvořena{" "}
                <b>zeleninou či ovocem.</b> Druhá bude tvořena{" "}
                <b>komplexními sacharidy.</b> Třetí bude tvořena{" "}
                <b>bílkovinami.</b> Ani jednu z těchto složek byste neměli
                opomíjet. Zelenina a sacharidy obsahují důležitou{" "}
                <b>vlákninu, také vitaminy a minerální látky.</b> Sacharidy jsou
                navíc potřebným <b>zdrojem energie.</b> Při vynechávání příloh
                se tak dost snadno může stát, že začnete mít chutě a sáhnete po
                jednoduchých cukrech. <b>Přílohy se</b> proto <b>nebojte</b> a
                nevynechávejte ji, je důležitou součástí jídelníčku. Bílkoviny
                jsou, jak už jsme si řekli, nejvíce sytící složkou, také bychom
                na ně tedy neměli zapomínat. Vhodné je konzumovat jak{" "}
                <b>rostlinné,</b> tak <b>živočišné</b> zdroje. Některé jsou
                důležitým zdrojem <b>vápníku.</b> Říkáte si, kam se poděly{" "}
                <b>tuky?</b> Tuky mají velkou energetickou hodnotu, netvoří
                proto celou třetinu, ale menší množství. Jídlo tak můžete
                doplnit o pár oříšků či semínek, nakrájené avokádo nebo pár lžic
                dýňového oleje.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Odložte talíř a{" "}
                <a href="https://nutritionpro.cz/" target="_blank">
                  čapněte krabičku!
                </a>{" "}
                S tou si vždy můžete být jisti, že je váš jídelníček chutný a
                plný živin.
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default ZdraveStravovani
